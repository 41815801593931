
<template>
<v-app>
    <dashboard-core-view />

    <dashboard-core-settings />
    
    

  <div class="text-center" >
        
        <br/><br/><br/><br/>
        <div  style="color:red; font-size:44px;text-align: center;">
          <v-icon style="color:red; font-size:44px;text-align: center;" right dark>mdi-lock</v-icon>

          Acesso Restrito


        </div>

  
         <div style="color:red; font-size:16px">
                  Usuário não tem permissão para acessar está área 
        </div>
        
  </div>


</v-app>

</template>

<script>
export default {
  name: 'Restrito'
}
</script>